<template>
	<form class="container">
		<a-tutorial-video videoSrc="https://www.youtube.com/embed/lrf-GAYUOkQ"></a-tutorial-video>
		<div class="d-flex flex-wrap align-items-center w-100 justify-content-center mb-5">
			<div class="col-md-2 mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start pl-md-0">
				<z-back-button @click.native="back" />
			</div>
			<div class="col-12 col-md-8">
				<h3 class="mb-2">Údaje o študentoch</h3>
			</div>
			<div class="col-md-2"></div>
		</div>

		<!--<span dev @click="DEV_prefill">prefill</span>-->
		<template v-if="orderProductType">
			<div v-for="(student, i) in students" :key="i">
				<h4 class="d-flex align-items-center">Objednávka pre študenta {{ i + 1 }}</h4>

				<w-input v-model="$v.students.$each[i].id" tag="b-form-select" :selectOptions="orderStudentsDropdown" @change="onStudentChange($event, i, student.studentIndex)" />

				<div class="row">
					<div class="col-lg-5">
						<w-input v-model="$v.students.$each[i].name" label="Meno" chevron />
					</div>
					<div class="col-lg-5">
						<w-input v-model="$v.students.$each[i].surname" label="Priezvisko" chevron />
					</div>
					<div class="col-lg-2">
						<w-input v-model="$v.students.$each[i].gender" label="Pohlavie" tag="b-form-select" :selectOptions="genderDropdownOptions" chevron />
					</div>
					<div class="col-lg-6">
						<w-input v-model="$v.students.$each[i].birth_year" label="Rok narodenia" tag="b-form-select" :selectOptions="years" chevron />
					</div>

					<template v-if="!isLevelUp">
						<div class="col-lg-4">
							<w-input v-model="$v.students.$each[i].school" label="Škola, ktorú študent navštevuje" chevron />
						</div>
						<div class="col-lg-2">
							<w-input v-model="$v.students.$each[i].school_class" label="Trieda" chevron />
						</div>
						<div class="col-lg-4">
							<w-form-radio
								v-model="$v.students.$each[i].is_dietary_restrictions_shown"
								label="Obmedzenia stravy:"
								:options="[
									{ label: 'Áno', value: 1 },
									{ label: 'Nie', value: 0 }
								]"
							/>
							<w-input v-if="student.is_dietary_restrictions_shown" v-model="$v.students.$each[i].dietary_restrictions" tag="b-form-textarea" />
						</div>
						<div class="col-lg-4">
							<w-form-radio
								v-model="$v.students.$each[i].is_health_information_shown"
								label="Dôležité zdravotné informácie:"
								:options="[
									{ label: 'Áno', value: 1 },
									{ label: 'Nie', value: 0 }
								]"
							/>
							<w-input v-if="student.is_health_information_shown" v-model="$v.students.$each[i].health_information" placeholder="Lieky, alergie..." tag="b-form-textarea" />
						</div>
						<div class="col-lg-4">
							<w-form-radio
								v-model="$v.students.$each[i].is_educational_needs_shown"
								label="Špeciálne výchovno-vzdelávacie potreby:"
								:options="[
									{ label: 'Áno', value: 1 },
									{ label: 'Nie', value: 0 }
								]"
							/>
							<w-input v-if="student.is_educational_needs_shown" v-model="$v.students.$each[i].educational_needs" tag="b-form-textarea" />
						</div>
					</template>
				</div>

				<div v-if="orderRegistrations[i].product_period.product && orderRegistrations[i].product_period.product.questionnaire">
					<h5 class="mb-1 mt-3">
						Mám záujem vyplniť osobnostný dotazník môjho dieťaťa
						<z-tooltip text="Vyplnený dotazník nám napovie viac o zručnostiach, obľúbených aktivitách a ambíciách dieťaťa, odporúčame Vám jeho vyplnenie spolu s dieťaťom" />
					</h5>

					<w-form-radio
						v-model="$v.students.$each[i].is_questionnaire_shown"
						:options="[
							{ label: 'Áno', value: 1 },
							{ label: 'Nie', value: 0 }
						]"
					/>

					<a-student-questionnaire v-if="student.is_questionnaire_shown" v-model="student.answers" :questions="orderRegistrations[i].product_period.product.questionnaire.questions" />
					<span v-if="student.is_questionnaire_shown && !$v.students.$each[i].answers.everyQuestionHasAnswer" class="text-danger">
						Každá otázka musí mať vybranú odpoveď.
					</span>
				</div>

				<hr class="mb-4" />
			</div>
		</template>

		<b-button pill :disabled="isLoading" variant="success" class="z-submit-btn float-right" @click="onNext">
			<span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
			Pokračovať
		</b-button>
	</form>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import studentTemplate from './utils/student.template'
import productUtils from '@/plugins/app/_utils/product.util.js'

export default {
	components: {
		'a-student-questionnaire': () => import('@/plugins/app@user/register/components/a-student-questionnaire.vue'),
		'z-tooltip': () => import('@/plugins/app@components/tooltip/z-tooltip.vue'),
		'a-tutorial-video': () => import('@/plugins/app/order/components/a-tutorial-video.vue')
	},

	async mounted() {
		this._trackDataLayerEvent()
		this._loadOrder()
		this._prefillStudents()
	},

	data() {
		return {
			isLoading: false,
			students: [],
			userStudents: [],
			orderId: null,
			genderDropdownOptions: [
				{ value: 'male', text: 'chlapec' },
				{ value: 'female', text: 'dievča' }
			]
		}
	},

	validations() {
		return this.rules
	},

	computed: {
		...mapFields('order', ['orderRegistrations']),
		...mapGetters('order', ['orderProductType']),

		isLevelUp() {
			return productUtils.isLevelUp(this.orderProductType)
		},
		isCourse() {
			return productUtils.isCourse(this.orderProductType)
		},

		years() {
			const todayYear = new Date().getFullYear()
			const years = []
			for (let year = new Date(0).getFullYear(); year <= todayYear - 7; year++) years.push(year)
			return years.reverse()
		},

		orderStudentsDropdown() {
			const dropdownData = this.userStudents.map((student) => {
				return {
					text: `${student.name} ${student.surname}`,
					value: student.id
				}
			})
			return [...dropdownData, { text: 'Nový študent', value: null }]
		},

		selectedStudentsValid() {
			const studentIds = this.students.map((s) => Number(s.id)).filter((id) => id) // new student have id 0, we need to filter out new students from this array
			return [...new Set(studentIds)].length == studentIds.length
		},

		rules() {
			const rules = {
				students: {
					required,
					minLength: minLength(1),
					$each: {
						id: {},
						name: { required },
						surname: { required },
						gender: { required },
						birth_year: { required },
						school: { required },
						school_class: { required },
						dietary_restrictions: { required: requiredIf((m) => !!m.is_dietary_restrictions_shown) },
						educational_needs: { required: requiredIf((m) => !!m.is_educational_needs_shown) },
						health_information: { required: requiredIf((m) => !!m.is_health_information_shown) },
						is_dietary_restrictions_shown: { required },
						is_educational_needs_shown: { required },
						is_health_information_shown: { required },
						is_questionnaire_shown: { required: requiredIf((m) => !!m.is_questionnaire_available) },
						answers: {
							everyQuestionHasAnswer: (answers, student) => {
								if (!student.is_questionnaire_shown) return true
								if (!answers.length) return false
								const answeredQuestionsIds = answers.map((a) => a.question_id)
								return student.available_questions.every((q) => answeredQuestionsIds.includes(q.id))
							}
						}
					}
				}
			}
			if (this.isLevelUp) {
				delete rules.students.$each.school
				delete rules.students.$each.school_class
				delete rules.students.$each.dietary_restrictions
				delete rules.students.$each.is_dietary_restrictions_shown
				delete rules.students.$each.educational_needs
				delete rules.students.$each.is_educational_needs_shown
				delete rules.students.$each.health_information
				delete rules.students.$each.is_health_information_shown
			}
			return rules
		}
	},

	methods: {
		...mapActions('order', ['updateOrder']),
		async onNext() {
			if (this.$v.$invalid) {
				this.$v.$touch()
				return
			}

			if (!this.selectedStudentsValid) {
				this.$wToast.error('Jeden študent je priradený k viacerým registráciám.')
				return
			}

			this.isLoading = true
			for (const s of this.students) {
				const student = await this._saveStudent(s)

				this.orderRegistrations
					.filter((or) => or.fe_custom_data.student_index == s.studentIndex)
					.forEach((or) => {
						or.student = student
						or.student_id = student.id
					})
			}

			this.updateOrder()
				.then(() => this.$router.push({ name: 'Summary' }))
				.finally(() => (this.isLoading = false))
		},

		onStudentChange(studentId, index, studentIndex) {
			const newVal = { ...studentTemplate, ...this.userStudents.find((s) => s.id == studentId) }
			const props = ['id', 'name', 'surname', 'gender', 'birth_year', 'school', 'school_class', 'dietary_restrictions', 'health_information', 'educational_needs', 'answers', 'available_questions', 'is_dietary_restrictions_shown', 'is_educational_needs_shown', 'is_health_information_shown', 'is_questionnaire_shown']
			props.forEach((prop) => (this.students[index][prop] = newVal[prop]))

			if (this.isLevelUp) this.students[index].is_questionnaire_shown = 0

			if (this.isLevelUp) {
				this.student.is_questionnaire_shown = 0
				this.student.is_health_information_shown = 0
				this.student.is_educational_needs_shown = 0
				this.student.is_dietary_restrictions_shown = 0
			}

			this.orderRegistrations.filter((or) => or.fe_custom_data.student_index == studentIndex).forEach((or) => (or.student_id = studentId || null))

			// if "novy student" option is selected, we need to reset validation to prevent showing of error messages
			if (!this.students[index].id) setTimeout(() => this.$v.$reset())
			else this.$v.$touch()
		},

		back() {
			this.$router.push({ name: 'Order', params: { type: this.orderProductType } })
		},

		async _loadOrder() {
			try {
				// refresh user token before loading order
				await this.$store.dispatch('wAuth/userInfo')

				const orderData = await this.$store.dispatch('order/getOrder')
				this.orderId = orderData.id
				if (orderData.user) this.userStudents = orderData.user.students
				this._prefillStudents()
			} catch (e) {
				this.$wToast.error(e)
			}
		},

		async _saveStudent(student) {
			if (this.isLevelUp) {
				delete student.school
				delete student.school_class
				delete student.dietary_restrictions
				delete student.is_dietary_restrictions_shown
				delete student.educational_needs
				delete student.is_educational_needs_shown
				delete student.health_information
				delete student.is_health_information_shown
			}
			try {
				const response = await wAxios[student.id ? 'patch_auth_data' : 'post_auth_data'](
					`v1/auth/students/${student.id || ''}`,
					// QUICK FIX: student is in weird format
					JSON.parse(JSON.stringify(student))
				)
				this.$store.dispatch('wAuth/userInfo')
				return student.id ? response : response.data.data
			} catch (error) {
				this.$wToast.error(error)
			}
		},

		_prefillStudents() {
			this.students = this.orderRegistrations.reduce((students, or, i) => {
				if (students.some((s) => s.studentIndex == or.fe_custom_data.student_index)) return students

				// if order registration does not have student assigned, then autimatically assign registered students
				if (!or.student) {
					or.student = this.userStudents[i]
					or.student_id = this.userStudents[i] ? this.userStudents[i].id : null
				}

				const student = { ...studentTemplate, ...or.student, studentIndex: or.fe_custom_data.student_index }

				student.is_questionnaire_available = !!(or.product_period.product && or.product_period.product.questionnaire)

				if (this.isCourse) student.is_questionnaire_shown = 0

				if (this.isLevelUp) {
					student.is_questionnaire_shown = 0
					student.is_health_information_shown = 0
					student.is_educational_needs_shown = 0
					student.is_dietary_restrictions_shown = 0
				}

				students.push(student)

				return students
			}, [])

			// show validation for every prefilled student
			this.students.forEach((s, i) => {
				if (s.id) this.$v.students.$each[i].$touch()
			})
		},

		_trackDataLayerEvent() {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'checkout_progress',
				pageTitle: 'Údaje o študentoch',
				product: this.orderRegistrations ? this.orderRegistrations[0].product_period.product : ''
			})
		},

		DEV_prefill() {
			this.students = this.students.map(() => {
				return {
					id: null,
					name: 'testing',
					surname: 'testing',
					birth_year: 1995,
					school: 'testing',
					school_class: 'testing',
					gender: 'male'
				}
			})
			this.$v.$touch()
		}
	}
}
</script>
